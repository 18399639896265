import React from "react";
import axios from "axios";
import moment from "moment";

import { API_BASE_URL } from "./GoogleMyBusiness/variables/Variables";
import {
  Anchor,
  Affix,
  Badge,
  Collapse,
  Divider,
  Icon,
  Row,
  Col,
  Empty,
  List,
  Select,
  Tabs,
  Button
} from "antd";

import ProofItem from "./GoogleMyBusiness/ProofItem";
import LoadingIndicator from "./LoadingIndicator";
// import GoogleSearchAd from "./GoogleSearchAd";

const { TabPane } = Tabs;

const CONTENT_MAX_WIDTH = 1400;

const PROOF_ITEM_TYPES = [
  { id: "DISPLAY", name: "Display", icon: "picture" },
  { id: "EMAIL", name: "Email", icon: "mail" },
  { id: "GMB", name: "Google My Business", icon: "google" },
  { id: "SEARCH", name: "Search", icon: "search" },
  { id: "SOCIAL", name: "Social", icon: "user-add" }
];

let TypeGroupedList = props => {
  return PROOF_ITEM_TYPES.map(itemType => (
    <Collapse
      key={itemType.id.toLowerCase()}
      defaultActiveKey={itemType.id.toLowerCase()}
      style={{ marginBottom: 16 }}
    >
      <Collapse.Panel
        key={itemType.id.toLowerCase()}
        header={
          <span>
            <Icon type={itemType.icon} style={{ marginRight: 8 }} />
            {itemType.name}
            <Badge
              count={
                props.data &&
                props.data.filter(item => item.proof_type === itemType.id)
                  .length
              }
              style={{
                backgroundColor: "#dbdbdb",
                color: "#707070",
                marginLeft: 8
              }}
            />
          </span>
        }
        id={itemType.id.toLowerCase()}
        style={{ backgroundColor: "#bae7ff" }}
      >
        {/* <div id={itemType.id.toLowerCase()}> */}
        {/* <Divider orientation="left">{itemType.name}</Divider> */}
        <List
          itemLayout="horizontal"
          dataSource={props.data.filter(
            item => item.proof_type === itemType.id
          )}
          locale={{ emptyText: "No Items" }}
          renderItem={post => (
            <ProofItem
              post={post}
              user={props.user}
              proofingContacts={props.proofingContacts}
              onApprove={props.onApprove}
            />
          )}
        />
      </Collapse.Panel>
    </Collapse>
  ));
};

export default class ProofPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      //   accountId: null,
      //   locationId: null,
      //   locationData: null,
      //   posts: null,
      backendPosts: null,
      sortBy: "type",
      proofingContacts: [],
      //   affixed: true,
      //   token: null,
      //   isTourOpen: false,
      //   activeTab: props.match.params.state || "proofing",
      loadingInternal: true
      //   loadingLive: true
    };
  }

  componentDidMount() {
    const {
      match: { params }
    } = this.props;

    this.setState({ ...params });

    this.getData();
  }

  getData() {
    // Get basic client data
    axios.get(`${API_BASE_URL}clients`).then(res => {
      let client = res.data.clients.find(
        client => client.item_id + "" === this.props.match.params.companyId
      );
      this.setState({ clients: res.data.clients, client });
    });

    this.getProofItems();
    this.loadProofingContacts();
  }

  getProofItems = () => {
    axios
      .get(
        `${API_BASE_URL}proofing/company/${this.props.match.params.companyId}/proofItems`
      )
      .then(res => {
        this.setState({
          proof_items: res.data.proof_items.reverse(),
          loadingInternal: false
        });
      });
  };

  loadProofingContacts = () => {
    if (!this.props.match.params.companyId) {
      return;
    }

    const url = `${API_BASE_URL}proofing/company/${this.props.match.params.companyId}/proofingContacts`;

    return axios({
      method: "GET",
      url
    }).then(res => {
      this.setState({ proofingContacts: res.data.proofingContacts });
    });
  };

  render() {
    const { user } = this.props;

    const {
      // match,
      proof_items,
      // localPosts,
      locationData
    } = this.state;

    let proofingPosts,
      approvedPosts,
      scheduledPosts,
      publishedPosts,
      activePosts,
      expiredPosts;

    if (proof_items) {
      proofingPosts = proof_items.filter(post => post.state === "PROOFING");
      approvedPosts = proof_items.filter(post => post.state === "APPROVED");
      scheduledPosts = proof_items.filter(post => post.state === "PUBLISHED");
      publishedPosts = proof_items.filter(post => post.state === "LIVE");
    }

    // if (localPosts) {
    //   activePosts = this.state.localPosts.filter(
    //     post =>
    //       post.state === "LIVE" &&
    //       moment(post.updateTime).isAfter(moment().subtract(1, "week"))
    //   );
    //   expiredPosts = this.state.localPosts.filter(
    //     post =>
    //       post.state === "LIVE" &&
    //       moment(post.updateTime).isBefore(moment().subtract(1, "week"))
    //   );
    // }

    return (
      <div
        className={"content"}
        style={{ height: "100%", padding: "0 16px", marginBottom: 16 }}
      >
        <Row>
          {this.state.sortBy === "type" && (
            <Col
              xs={0}
              xl={3}
              // style={{ marginTop: 136 }}
            >
              {/* <Affix offsetTop={24 + 136}> */}
              <Anchor offsetTop={24} style={{ backgroundColor: "unset" }}>
                {PROOF_ITEM_TYPES.map(itemType => (
                  <Anchor.Link
                    key={itemType.id}
                    href={`#${itemType.id.toLowerCase()}`}
                    title={
                      <React.Fragment>
                        <span>
                          <Icon
                            type={itemType.icon}
                            style={{ marginRight: 8 }}
                          />
                          {itemType.name}
                        </span>
                        <span
                          style={{
                            float: "right",
                            marginRight: 8
                          }}
                        >
                          <Badge
                            count={
                              proofingPosts &&
                              proofingPosts.filter(
                                item => item.proof_type === itemType.id
                              ).length
                            }
                            style={{
                              backgroundColor: "#dbdbdb",
                              color: "#707070"
                            }}
                          />
                        </span>
                      </React.Fragment>
                    }
                  />
                ))}
              </Anchor>
              {/* </Affix> */}
            </Col>
          )}
          <Col>
            <div
              style={{
                maxWidth: CONTENT_MAX_WIDTH,
                margin: "0 auto"
              }}
            >
              <div style={{ marginTop: 16 }}>
                <h1 style={{ display: "inline-block", marginRight: 8 }}>
                  {this.state.client && this.state.client.company_name}
                </h1>
                <span style={{ display: "inline-block" }}>
                  {this.state.client && this.state.client.location}
                </span>
              </div>

              <Tabs
                defaultActiveKey={this.props.match.params.state || "proofing"}
                animated={false}
                onChange={key => {
                  this.props.history.push(
                    `/proofs/${this.props.match.params.companyId}/${key}`
                  );
                  this.setState({ activeTab: key });
                }}
                style={{ maxWidth: CONTENT_MAX_WIDTH, margin: "16px auto 0" }}
                tabBarExtraContent={
                  <span>
                    {/* <Button
                      size="small"
                      icon={"vertical-align-bottom"}
                      style={{ marginRight: 16 }}
                    /> */}
                    Sort by:&nbsp;
                    <Select
                      size="small"
                      defaultValue={this.state.sortBy}
                      style={{ width: 125 }}
                      onChange={value => {
                        console.log(`selected ${value}`);
                        this.setState({ sortBy: value });
                      }}
                    >
                      <Select.Option value="date">Date Created</Select.Option>
                      <Select.Option value="type">Type</Select.Option>
                    </Select>
                  </span>
                }
              >
                <TabPane
                  tab={
                    <>
                      Proofing{" "}
                      <Badge
                        count={proofingPosts && proofingPosts.length}
                        style={{
                          backgroundColor: "#bae7ff",
                          color: "#096dd9",
                          marginLeft: 8,
                          boxShadow: "none"
                        }}
                      />
                    </>
                  }
                  key="proofing"
                >
                  <div
                    style={{
                      // textAlign: "center",
                      height: "100%"
                    }}
                  >
                    {this.state.loadingInternal ? (
                      <LoadingIndicator />
                    ) : proofingPosts && proofingPosts.length > 0 ? (
                      this.state.sortBy === "type" ? (
                        <TypeGroupedList
                          data={proofingPosts}
                          user={user}
                          onApprove={this.getProofItems}
                          proofingContacts={this.state.proofingContacts}
                        />
                      ) : (
                        <List
                          itemLayout="horizontal"
                          dataSource={proofingPosts}
                          renderItem={post => (
                            <ProofItem
                              post={post}
                              user={user}
                              onApprove={this.getProofItems}
                              proofingContacts={this.state.proofingContacts}
                            />
                          )}
                        />
                      )
                    ) : (
                      <Empty
                        description={"You have no proofs awaiting approval"}
                        style={{ margin: "80px 0", height: "100%" }}
                      />
                    )}
                  </div>
                </TabPane>

                <TabPane tab="Approved" key="approved">
                  <div
                    style={{
                      // textAlign: "center",
                      height: "100%"
                    }}
                  >
                    {this.state.loadingInternal ? (
                      LoadingIndicator
                    ) : approvedPosts && approvedPosts.length > 0 ? (
                      this.state.sortBy === "type" ? (
                        <TypeGroupedList
                          data={approvedPosts}
                          user={user}
                          proofingContacts={this.state.proofingContacts}
                          // onApprove={this.getProofItems}
                        />
                      ) : (
                        <List
                          itemLayout="horizontal"
                          dataSource={approvedPosts}
                          renderItem={post => (
                            <ProofItem
                              post={post}
                              user={user}
                              proofingContacts={this.state.proofingContacts}
                            />
                          )}
                        />
                      )
                    ) : (
                      <Empty
                        description={"You have no approved proofs"}
                        style={{ margin: "80px 0", height: "100%" }}
                      />
                    )}
                  </div>
                </TabPane>

                <TabPane tab="Scheduled" key="scheduled">
                  <React.Fragment>
                    <div
                      style={{
                        // textAlign: "center",
                        height: "100%"
                      }}
                    >
                      {this.state.loadingInternal ? (
                        LoadingIndicator
                      ) : scheduledPosts && scheduledPosts.length ? (
                        <List
                          itemLayout="horizontal"
                          dataSource={scheduledPosts}
                          renderItem={post => (
                            <ProofItem
                              post={post}
                              user={user}
                              proofingContacts={this.state.proofingContacts}
                            />
                          )}
                        />
                      ) : (
                        <Empty
                          description={"You have no scheduled items"}
                          style={{ margin: "80px 0", height: "100%" }}
                        />
                      )}
                    </div>
                  </React.Fragment>
                </TabPane>

                <TabPane tab="Published" key="published">
                  <React.Fragment>
                    <div
                      style={{
                        // textAlign: "center",
                        height: "100%"
                      }}
                    >
                      {this.state.loadingInternal ? (
                        LoadingIndicator
                      ) : publishedPosts && publishedPosts.length ? (
                        <List
                          itemLayout="horizontal"
                          dataSource={publishedPosts}
                          renderItem={post => (
                            <ProofItem
                              post={post}
                              user={user}
                              proofingContacts={this.state.proofingContacts}
                            />
                          )}
                        />
                      ) : (
                        <Empty
                          description={"You have no published items"}
                          style={{ margin: "80px 0", height: "100%" }}
                        />
                      )}
                    </div>
                  </React.Fragment>
                </TabPane>

                {/* <TabPane tab="Published" key="published">
                <>
                  {this.state.loadingLive ? (
                    LoadingIndicator
                  ) : localPosts && localPosts.length > 0 ? (
                    <List
                      itemLayout="horizontal"
                      dataSource={localPosts}
                      renderItem={post => (
                        <ProofItem
                          key={post.name}
                          post={{ post_body: post }}
                          user={user}
                          commentsDisabled
                        />
                      )}
                    />
                  ) : (
                    <Empty
                      description={"You have no published posts"}
                      style={{ margin: "80px 0", height: "100%" }}
                    />
                  )}
                </>
              </TabPane> */}
              </Tabs>

              {/* {this.state.posts &&
                                        this.state.posts.map(post => (
                                            <div style={{ textAlign: 'left', margin: 'auto', maxWidth: 500 }}>
                                                <PostPreview post={post} key={post.name} />
                                                <div style={{ marginBottom: 32 }}>
                                                    <Button type="primary" size="large" style={{ marginRight: 8 }}>
                                                        Approve
                                                    </Button>
                                                    <Button tyle="danger" size="large">
                                                        Request changes
                                                    </Button>
                                                </div>
                                            </div>
                                        ))} */}
              {/* <Divider orientation="left">Scheduled Posts</Divider>
                                <Divider orientation="left">Expired Posts</Divider> */}
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}
