import React from "react";

import "./GoogleSearchAd.css";

export default class GoogleSearchAd extends React.Component {
  render() {
    const { ad } = this.props;

    if (!ad) {
      return null;
    }

    return (
      <div
        style={{
          backgroundColor: "#fff",
          // border: "1px solid rgba(0,0,0,.13)",
          padding: "15px"
        }}
      >
        <div className="ads-ad" style={{ maxWidth: 600 }}>
          <div className="ad_cclk">
            {/* <a
            style={{ display: "none" }}
            href="/aclk?sa=l&amp;ai=DChcSEwidgI72767nAhVbH60GHY2FC3QYABAAGgJwdg&amp;sig=AOD64_1oMEo4r8fhpQQ4zPRV3_hmjWzNHQ&amp;q=&amp;ved=2ahUKEwiElob2767nAhWYhJ4KHR6HBokQ0Qx6BAgVEAE&amp;adurl="
            id="n1s0p1c0"
          ></a> */}
            <a
              className="V0MxL r-i_KO0vTSVJz4"
              href="https://www.infinitycapitolhillapartments.com/"
              target="_blank"
              rel="noopener noreferrer"
              id="vn1s0p1c0"
              data-preconnect-urls="https://www.infinitycapitolhillapartments.com/"
              data-prefetch-urls="https://cdngeneral.rentcafe.com/ClientCustom/themecustomizer/company_97184/Portals/Published/portal_2334875/sideNav/Infinity%20-%20Side%20Nav/css/sideNav_home.min.css?v=1555929385,https://www.infinitycapitolhillapartments.com/css/responsive-fixed-nav.min.css"
              jscontroller=""
              jsname="yzW4sd"
              jsl="$t t-r1glFWqNI5A;$x 0;"
            >
              <div className="ads-visurl">
                <span className="VqFMTc p8AiDd">
                  Ad<span style={{ padding: "0 5px" }}>·</span>
                </span>
                <cite className="UdQCqe">{ad.url}</cite>‎
                <span className="evvN5c">
                  <span className="e1ycic">
                    <span className="aii">
                      <div className="action-menu YMEk9e TxG06d">
                        <span className="mn-dwn-arw"></span>
                      </div>
                    </span>
                  </span>
                </span>
                {ad.phone && (
                  <span className="phone">
                    <span className="PXnpre" data-hveid="CBcQBg">
                      {ad.phone}
                    </span>
                  </span>
                )}
              </div>
              <br />
              <h3 className="sA5rQ">
                {ad.headline_1} | {ad.headline_2}
              </h3>
            </a>
            {/* <div className="nYN2jf ads-visurl">
              <span className="evvN5c VqFMTc p8AiDd">
                Ad<span style={{ padding: "0 5px" }}>·</span>
              </span>
              <cite className="UdQCqe evvN5c">{ad.url}</cite>‎ */}
            {/* <span>
                <span className="e1ycic">
                  <span
                    id="aiis1"
                    className="aii r-ipcDC_jGF6D4"
                    data-async-context="wtad:CtwCCAEQARgBmgEhaW5maW5pdHljYXBpdG9saGlsbGFwYXJ0bWVudHMuY29twgEhaW5maW5pdHljYXBpdG9saGlsbGFwYXJ0bWVudHMuY29t6gGCAltudWxsLG51bGwsbnVsbCxudWxsLFsxLG51bGwsW1sid3d3LmluZmluaXR5Y2FwaXRvbGhpbGxhcGFydG1lbnRzLmNvbS8iXQosImluZmluaXR5Y2FwaXRvbGhpbGxhcGFydG1lbnRzLmNvbSIsImluZmluaXR5Y2FwaXRvbGhpbGxhcGFydG1lbnRzLmNvbSJdCiwyLG51bGwsbnVsbCxudWxsLG51bGwsW1siMzE2NTgyMDk4MzkxIl0KLFsiMTU4MDUwOTI4OTQ4MDIyMSIsMTEyMDA5MDUxLDE5NDY5MTIxNDFdCiwiYXBhcnRtZW50cyBzZWF0dGxlIl0KXQpdCooCAnMxsAQB"
                    data-async-trigger="wta_async_s1"
                    jscontroller=""
                    jsaction="menu_item_selected:r.Xx1sV_Jzb9Q"
                    data-rtid="ipcDC_jGF6D4"
                    jsl="$t t-FcRZKOABPPk;$x 0;"
                  >
                    <div
                      jsname="v7hl4d"
                      className="ipcDC_jGF6D4-8Fk4a8EszjQ k1sBMc y yp"
                      id="wta_async_s1"
                      data-jiis="up"
                      data-async-type="wta"
                      data-async-context-required="wtad"
                      data-ved="2ahUKEwiElob2767nAhWYhJ4KHR6HBokQqa8FegQIFRAC"
                    ></div>
                    <g-dropdown-menu
                      jscontroller="DqdCgd"
                      jsdata="xJGXK;;A9e8VQ"
                      jsaction="rcuQ6b:npT2md;menu_item_selected:GKlhgf;g_popup_before_reposition:cCpuJc;dp_menu_reg_caption:bzkPEc;clear_menu_item:oyYkKb;g_popup_show:G8Ofmd;g_popup_hide:rWoVB;hide_popup:L76sMb;menu_close_button:L76sMb"
                    >
                      <g-popup
                        jsname="zpo2ue"
                        jscontroller="NZI0Db"
                        jsdata="mVjAjf;;A9e8VU"
                        id="ow19"
                        __is_owner="true"
                      >
                        <div
                          jsname="oYxtQd"
                          className="rIbAWc hide-focus-ring"
                          aria-haspopup="true"
                          role="button"
                          tabindex="0"
                          jsaction="WFrRFb"
                        >
                          <div
                            className="YMEk9e action-menu TxG06d"
                            title="Why this ad?"
                            aria-label="Why this ad?"
                            role="button"
                            tabindex="0"
                            data-ved="2ahUKEwiElob2767nAhWYhJ4KHR6HBokQtpYBegQIFRAD"
                          >
                            <span className="mn-dwn-arw" id="zvzE9"></span>
                          </div>
                        </div>
                        <div
                          jsname="V68bde"
                          className="EwsJzb sAKBe"
                          style={{ display: "none", zIndex: 1 }}
                          jsowner="ow19"
                        >
                          <g-menu
                            jsname="iXXGtd"
                            className="e7RZX gLSAk"
                            jscontroller="wQpTuc"
                            data-mh="-1"
                            role="menu"
                            tabindex="-1"
                            jsaction="PSl28c;keydown:uYT2Vb;mouseenter:WOQqYb;mouseleave:Tx5Rb;mouseover:IgJl9c;rcuQ6b:npT2md"
                            data-ved="2ahUKEwiElob2767nAhWYhJ4KHR6HBokQ0ZYBegQIFRAE"
                          >
                            <g-menu-item
                              jsname="NNJLud"
                              jscontroller="qjr3nc"
                              className="ErsxPb hide-focus-ring"
                              role="menuitem"
                              tabindex="-1"
                              data-short-label=""
                              jsdata="zPXzie;;A9e8VY"
                              jsaction="rcuQ6b:npT2md"
                              data-ved="2ahUKEwiElob2767nAhWYhJ4KHR6HBokQqZsFegQIFRAF"
                            >
                              <div jsname="ibnC6b" className="znKVS">
                                <div className="NC2iFe">
                                  <span
                                    className="c0Xbsc z1asCe A2gxLb"
                                    style={{
                                      height: 22,
                                      lineHeight: 22,
                                      width: 22
                                    }}
                                  >
                                    <svg
                                      focusable="false"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                    >
                                      <path d="M11 18h2v-2h-2v2zm1-16C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm0-14c-2.21 0-4 1.79-4 4h2c0-1.1.9-2 2-2s2 .9 2 2c0 2-3 1.75-3 5h2c0-2.25 3-2.5 3-5 0-2.21-1.79-4-4-4z"></path>
                                    </svg>
                                  </span>
                                  <span className="TTTqTe">Why this ad?</span>
                                </div>
                              </div>
                            </g-menu-item>
                          </g-menu>
                        </div>
                      </g-popup>
                    </g-dropdown-menu>
                  </span>
                </span>
              </span> */}
            {/* </div> */}
          </div>
          <div className="ads-creative">
            {ad.description_line_1} {ad.description_line_2}
          </div>
          {ad.callouts && (
            <ul className="OkkX2d">
              {ad.callouts.map((item, i) => (
                <li key={i}>
                  <a
                    className="V0MxL"
                    href={item.url || ad.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {item.title}
                  </a>
                </li>
              ))}
              {/* <li>
                <a
                  className="V0MxL"
                  data-arwt="/aclk?sa=l&amp;ai=DChcSEwiTteHQ8q7nAhXOIK0GHXKRBJQYABAGGgJwdg&amp;sig=AOD64_1_0d8h2qw2QVmkMRefkBlawbw-ZA&amp;adurl=&amp;q="
                  href="https://www.infinitycapitolhillapartments.com/wa/seattle/floorplans.aspx"
                >
                  Floor Plans
                </a>
              </li>
              <li>
                <a
                  className="V0MxL"
                  data-arwt="/aclk?sa=l&amp;ai=DChcSEwiTteHQ8q7nAhXOIK0GHXKRBJQYABAHGgJwdg&amp;sig=AOD64_3-5K-HR5zy48K_yAudXA-eUWCb-A&amp;adurl=&amp;q="
                  href="https://www.infinitycapitolhillapartments.com/wa/seattle/photogallery.aspx"
                >
                  Gallery
                </a>
              </li>
              <li>
                <a
                  className="V0MxL"
                  href="/aclk?sa=l&amp;ai=DChcSEwiTteHQ8q7nAhXOIK0GHXKRBJQYABAIGgJwdg&amp;sig=AOD64_0cNdqIvGZlU1irZtgjMk2fUYugJg&amp;adurl=&amp;q=&amp;ved=2ahUKEwijgdrQ8q7nAhUJsp4KHT1nBJAQpigoA3oECBUQCg"
                >
                  Amenities
                </a>
              </li>
              <li>
                <a
                  className="V0MxL"
                  href="https://www.infinitycapitolhillapartments.com/wa/seattle/mapsanddirections.aspx"
                >
                  Location
                </a>
              </li> */}
            </ul>
          )}
        </div>
      </div>
    );
  }
}
