import React from 'react';
import { Affix, Alert, Row, Col, Button, Empty, List, Popconfirm, notification, Tabs, Badge } from 'antd';

import Tour from 'reactour';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

import axios from 'axios';
import jwt from 'jsonwebtoken';
import queryString from 'query-string';

import ProofItem from './ProofItem';

import LoadingIndicator from '../LoadingIndicator';

import RenewGMBPosts from './RenewGMBPosts';

import { API_BASE_URL } from './variables/Variables';
import { Redirect } from 'react-router-dom';

const { TabPane } = Tabs;

export default class GMBProofPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            accountId: null,
            locationId: null,
            locationData: null,
            posts: null,
            backendPosts: null,
            affixed: true,
            token: null,
            isTourOpen: false,
            activeTab: props.match.params.state || 'proofing',
            loadingInternal: true,
            loadingLive: true
        };
    }

    componentDidMount() {
        const {
            match: { params }
        } = this.props;
        const queryParameters = queryString.parse(this.props.location.search);

        // console.log(jwt.decode(queryParameters.token));

        this.setState({ ...params, token: queryParameters.token });

        axios
            .get(`${API_BASE_URL}gmb/accounts/${params.accountId}/locations/${params.locationId}/localPosts/proofing`)
            .then(res => {
                if (res.data.posts.length) {
                    res.data.posts.sort((a, b) => b.id - a.id);
                    this.setState({
                        backendPosts: res.data.posts
                    });

                    if (res.data.posts.filter(post => post.state === 'PROOFING').length) {
                        setTimeout(() => {
                            if (!localStorage['alreadyVisited']) {
                                this.startTour();
                                localStorage['alreadyVisited'] = true;
                            }
                        }, 500);
                    }
                }
            })
            .finally(() => {
                this.setState({
                    loadingInternal: false
                });
            });

        // Get live/expired GMB posts
        axios
            .get(`${API_BASE_URL}gmb/accounts/${params.accountId}/locations/${params.locationId}/localPosts`)
            .then(res => {
                this.setState({ localPosts: res.data.localPosts });
            })
            .finally(() => {
                this.setState({
                    loadingLive: false
                });
            });

        axios.get(`${API_BASE_URL}companyplatforms/gmb/${params.accountId}/${params.locationId}`).then(res => {
            this.setState({ client: res.data });
        });

        const MILLISECONDS_BETWEEN_POST_REFRESH = 30 * 1000;
        this.postRefreshInterval = setInterval(() => {
            if (!document.hidden) {
                this.getPosts();
            }
        }, MILLISECONDS_BETWEEN_POST_REFRESH);
    }

    componentWillUnmount() {
        clearInterval(this.postRefreshInterval);
    }

    getPosts = () => {
        axios
            .get(
                `${API_BASE_URL}gmb/accounts/${this.props.match.params.accountId}/locations/${this.props.match.params.locationId}/localPosts/proofing`
            )
            .then(res => {
                if (res.data.posts.length) {
                    res.data.posts.sort((a, b) => b.id - a.id);
                    this.setState({ backendPosts: res.data.posts });
                }
            });

        axios
            .get(
                `${API_BASE_URL}gmb/accounts/${this.props.match.params.accountId}/locations/${this.props.match.params.locationId}/localPosts`
            )
            .then(res => {
                this.setState({ localPosts: res.data.localPosts });
            });
    };

    handleApproveAll(user) {
        console.log('approving all');

        let proofingPosts = this.state.backendPosts.filter(post => post.state === 'PROOFING');

        proofingPosts.forEach(post => {
            this.setState({ approving: true });
            console.log('approving post:', post);

            let postBody = post.post_body;
            postBody.state = 'APPROVED';
            postBody.author = this.props.user;

            let url = !post.locationId
                ? `${API_BASE_URL}proofing/company/${post.odoo_entity_id}/proofItems/${post.id}`
                : `${API_BASE_URL}gmb/accounts/${post.odoo_entity_id}/locations/${post.locationId}/localPosts/${post.id}`;

            axios
                .patch(url, postBody)
                .then(res => {
                    this.setState({ postStatus: 'APPROVED' });
                })
                .catch(err => {
                    notification.error({
                        message: 'There was an error approving the post'
                    });
                })
                .finally(() => {
                    this.setState({ approving: false });
                    this.getPosts();
                });
        });
    }

    startTour = () => {
        this.disableBody();
        this.setState({ isTourOpen: true });
    };

    disableBody = target => disableBodyScroll(target);
    enableBody = target => enableBodyScroll(target);

    render() {
        const {
            // match,
            backendPosts,
            localPosts,
            locationData,
            token
        } = this.state;

        if (!['proofing', 'approved', 'scheduled', 'published'].includes(this.props.match.params.state)) {
            return (
                <Redirect
                    to={`/gmb/${this.props.match.params.accountId}/${this.props.match.params.locationId}/proofing`}
                />
            );
        }

        let user = jwt.decode(token);

        if (this.props.user) {
            user = this.props.user;
        }

        let proofingPosts, approvedPosts, publishedPosts; // activePosts, expiredPosts;

        if (backendPosts) {
            proofingPosts = backendPosts.filter(post => post.state === 'PROOFING');
            approvedPosts = backendPosts.filter(post => post.state === 'APPROVED');
            publishedPosts = backendPosts.filter(post => post.state === 'PUBLISHED');
        }

        // if (localPosts) {
        //   activePosts = this.state.localPosts.filter(
        //     post =>
        //       post.state === "LIVE" &&
        //       moment(post.updateTime).isAfter(moment().subtract(1, "week"))
        //   );
        //   expiredPosts = this.state.localPosts.filter(
        //     post =>
        //       post.state === "LIVE" &&
        //       moment(post.updateTime).isBefore(moment().subtract(1, "week"))
        //   );
        // }

        const steps = [
            {
                stepInteraction: false,
                selector: '.gmb-post-card',
                content: (
                    <div>
                        <p>Welcome to the Conversion Logix proofing system!</p>
                        <p>Here you can preview your posts, banners, and other media.</p>
                    </div>
                )
            },
            {
                selector: '.proof-comments',
                content: 'Leave your comments or requests for revision in this area.'
            },
            {
                selector: '.approve-button',
                content: 'Finally, approve your proofs and we will publish them as requested.'
            }
        ];

        return (
            <div style={{ height: '100%' }}>
                {user && user.isAgency && (
                    <Alert
                        // showIcon={false}
                        message="You are proofing as Conversion Logix. Do not share this link!"
                        banner
                    />
                )}
                <div className={'content'} style={{ height: '100%', padding: '0 16px', marginBottom: 16 }}>
                    <Tour
                        steps={steps}
                        isOpen={this.state.isTourOpen}
                        goToStep={0}
                        // onAfterOpen={this.disableBody}
                        onBeforeClose={() => {
                            this.enableBody();
                        }}
                        onRequestClose={() => {
                            this.setState({ isTourOpen: false });
                        }}
                        rounded={2}
                        lastStepNextButton={<Button type="primary">Get Started</Button>}
                    />
                    <Row>
                        <Col>
                            {/* {locationData && locationData.locationName && (
                <Title style={{ marginTop: 0 }}>
                  {locationData.locationName}
                </Title>
              )} */}

                            {/* {user && user.name && user.email && (
                <strong>{`Name: ${user.name}, Email: ${user.email}`}</strong>
              )} */}

                            <div style={{ maxWidth: 1000, margin: '16px auto 0' }}>
                                {this.state.client && (
                                    <div>
                                        <h1 style={{ display: 'inline-block', marginRight: 8 }}>
                                            {this.state.client.company_name}
                                        </h1>
                                        <span style={{ display: 'inline-block' }}>{this.state.client.location}</span>
                                    </div>
                                )}

                                <Tabs
                                    defaultActiveKey={this.props.match.params.state || 'proofing'}
                                    animated={false}
                                    onChange={key => {
                                        this.props.history.push(
                                            `/gmb/${this.props.match.params.accountId}/${this.props.match.params.locationId}/${key}`
                                        );
                                        this.setState({ activeTab: key });
                                    }}
                                >
                                    <TabPane
                                        tab={
                                            <>
                                                Proofing Posts{' '}
                                                <Badge
                                                    count={proofingPosts && proofingPosts.length}
                                                    style={{
                                                        backgroundColor: '#bae7ff',
                                                        color: '#096dd9',
                                                        // marginLeft: 8,
                                                        boxShadow: 'none'
                                                    }}
                                                />
                                            </>
                                        }
                                        key="proofing"
                                    >
                                        {/* <Divider orientation="left">
                    Your Posts awaiting Approval{" "} */}
                                        {/* eslint-disable-next-line */}
                                        {/* <a onClick={this.startTour}>
                      <Icon type="question-circle" />
                    </a>
                  </Divider> */}
                                        <div
                                            style={{
                                                // textAlign: "center",
                                                height: '100%'
                                            }}
                                        >
                                            {this.state.loadingInternal ? (
                                                <LoadingIndicator />
                                            ) : proofingPosts && proofingPosts.length > 0 ? (
                                                // this.state.backendPosts.map(post => {
                                                //   return <ProofItem post={post} />;
                                                // })
                                                <List
                                                    itemLayout="horizontal"
                                                    dataSource={proofingPosts}
                                                    renderItem={post => (
                                                        // <List.Item>
                                                        <ProofItem post={post} user={user} onApprove={this.getPosts} />
                                                        // </List.Item>
                                                    )}
                                                />
                                            ) : (
                                                <Empty
                                                    description={'You have no posts awaiting approval'}
                                                    style={{ margin: '80px 0', height: '100%' }}
                                                />
                                            )}
                                        </div>
                                    </TabPane>

                                    <TabPane tab="Approved Posts" key="approved">
                                        {/* {approvedPosts && approvedPosts.length > 0 && ( */}
                                        {/* <React.Fragment>
                      <Divider orientation="left">Your Approved Posts</Divider> */}
                                        <div
                                            style={{
                                                // textAlign: "center",
                                                height: '100%'
                                            }}
                                        >
                                            {/* // this.state.backendPosts.map(post => {
                        //   return <ProofItem post={post} />;
                        // }) */}
                                            {this.state.loadingInternal ? (
                                                <LoadingIndicator />
                                            ) : approvedPosts && approvedPosts.length > 0 ? (
                                                <List
                                                    itemLayout="horizontal"
                                                    dataSource={approvedPosts}
                                                    renderItem={post => (
                                                        // <List.Item>
                                                        <ProofItem post={post} user={user} />
                                                        // </List.Item>
                                                    )}
                                                />
                                            ) : (
                                                <Empty
                                                    description={'You have no approved posts'}
                                                    style={{ margin: '80px 0', height: '100%' }}
                                                />
                                            )}
                                        </div>
                                        {/* </React.Fragment> */}
                                        {/* )} */}
                                    </TabPane>

                                    <TabPane tab="Scheduled Posts" key="scheduled">
                                        {/* {publishedPosts && publishedPosts.length > 0 && ( */}
                                        <React.Fragment>
                                            {/* <Divider orientation="left">Your Scheduled Posts</Divider> */}
                                            <div
                                                style={{
                                                    // textAlign: "center",
                                                    height: '100%'
                                                }}
                                            >
                                                {/* // this.state.backendPosts.map(post => {
                        //   return <ProofItem post={post} />;
                        // }) */}
                                                {this.state.loadingInternal ? (
                                                    <LoadingIndicator />
                                                ) : publishedPosts && publishedPosts.length ? (
                                                    <List
                                                        itemLayout="horizontal"
                                                        dataSource={publishedPosts}
                                                        renderItem={post => (
                                                            // <List.Item>
                                                            <ProofItem post={post} user={user} />
                                                            // </List.Item>
                                                        )}
                                                    />
                                                ) : (
                                                    <Empty
                                                        description={'You have no scheduled posts'}
                                                        style={{ margin: '80px 0', height: '100%' }}
                                                    />
                                                )}
                                            </div>
                                        </React.Fragment>
                                        {/* )} */}
                                    </TabPane>

                                    <TabPane tab="Published Posts" key="published">
                                        <RenewGMBPosts
                                            {...this.props}
                                            onSubmit={() => {
                                                this.getPosts();
                                            }}
                                        />
                                        {/* <>
                      {this.state.loadingLive ? (
                        <LoadingIndicator/>
                      ) : localPosts && localPosts.length > 0 ? (
                        <List
                          itemLayout="horizontal"
                          dataSource={localPosts}
                          renderItem={post => (
                            // <List.Item>
                            <ProofItem
                              key={post.name}
                              post={{ post_body: post }}
                              user={user}
                              commentsDisabled
                            />
                            // </List.Item>
                          )}
                        />
                      ) : (
                        <Empty
                          description={"You have no published posts"}
                          style={{ margin: "80px 0", height: "100%" }}
                        />
                      )}
                    </> */}
                                    </TabPane>

                                    {/* <TabPane tab="Active Posts" key="4">
                  {activePosts && activePosts.length > 0 && (
                    <>
                      <Divider orientation="left">Active Posts</Divider>
                      <List
                        itemLayout="horizontal"
                        dataSource={activePosts}
                        renderItem={post => (
                          // <List.Item>
                          <ProofItem
                            key={post.name}
                            post={{ post_body: post }}
                            user={user}
                            commentsDisabled
                          />
                          // </List.Item>
                        )}
                      />
                    </>
                  )}
                </TabPane> */}
                                </Tabs>
                            </div>

                            {/* {this.state.posts &&
                                        this.state.posts.map(post => (
                                            <div style={{ textAlign: 'left', margin: 'auto', maxWidth: 500 }}>
                                                <PostPreview post={post} key={post.name} />
                                                <div style={{ marginBottom: 32 }}>
                                                    <Button type="primary" size="large" style={{ marginRight: 8 }}>
                                                        Approve
                                                    </Button>
                                                    <Button tyle="danger" size="large">
                                                        Request changes
                                                    </Button>
                                                </div>
                                            </div>
                                        ))} */}
                            {/* <Divider orientation="left">Scheduled Posts</Divider>
                                <Divider orientation="left">Expired Posts</Divider> */}
                        </Col>
                    </Row>
                </div>
                {proofingPosts && proofingPosts.length > 0 && this.state.activeTab === 'proofing' && (
                    <Affix
                        offsetBottom={0}
                        onChange={affixed => {
                            this.setState({ affixed });
                        }}
                    >
                        <div
                            style={{
                                borderTop: '1px solid #e7e7e7',
                                backgroundColor: '#FFFFFF',
                                padding: '15px',
                                transition: 'box-shadow 0.5s',
                                boxShadow: this.state.affixed ? '0 -4px 6px -1px rgba(0,0,0,.1)' : 'none' //,0 -2px 4px -1px rgba(0,0,0,.06)'
                            }}
                        >
                            <Row>
                                <Col md={12}>
                                    <Popconfirm
                                        placement="topLeft"
                                        title="Are you sure you want to approve and publish all of your posts?"
                                        onConfirm={() => this.handleApproveAll(user)}
                                        // onCancel={cancel}
                                        okText="Yes"
                                        cancelText="No"
                                    >
                                        <Button
                                            className={`approve-button ${
                                                proofingPosts && proofingPosts.length && 'green-button'
                                            }`}
                                            type="primary"
                                            size="large"
                                            disabled={!proofingPosts || !proofingPosts.length}
                                            style={{
                                                marginRight: 8
                                                // backgroundColor: '#52c41a'
                                            }}
                                            loading={this.state.approving}
                                        >
                                            Approve All ({proofingPosts.length} post
                                            {proofingPosts.length > 1 && 's'})
                                        </Button>
                                    </Popconfirm>
                                </Col>
                            </Row>
                        </div>
                    </Affix>
                )}
            </div>
        );
    }
}
