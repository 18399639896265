import React from 'react';
import { Alert, Button, Empty, Form, Icon, Input, List, notification, Spin, Modal } from 'antd';

import axios from 'axios';
import jwt from 'jsonwebtoken';
import moment from 'moment';
import queryString from 'query-string';

import ProofItem from './ProofItem';

import { API_BASE_URL } from './variables/Variables';
import PostPreview from './PostPreview';

const { TextArea } = Input;

const dateFilterCutoff = moment()
    // .subtract(1, "month")
    .startOf('month');

const initialPostsToDisplay = 8;

export default class RenewGMBPosts extends React.Component {
    constructor() {
        super();
        this.state = {
            accountId: null,
            locationId: null,
            locationData: null,
            posts: null,
            backendPosts: null,
            localPosts: [],
            affixed: true,
            token: null,
            isTourOpen: false,
            loadingLive: true
        };
    }

    componentDidMount() {
        const {
            match: { params }
        } = this.props;
        const queryParameters = queryString.parse(this.props.location.search);

        this.setState({ ...params, token: queryParameters.token });

        axios
            .get(
                `${API_BASE_URL}gmb/accounts/${this.props.match.params.accountId}/locations/${this.props.match.params.locationId}/localPosts/proofing`
            )
            .then(res => {
                if (res.data.posts.length) {
                    res.data.posts.sort((a, b) => b.id - a.id);
                    this.setState({ backendPosts: res.data.posts });
                }
            });

        // Get live/expired GMB posts
        axios
            .get(`${API_BASE_URL}gmb/accounts/${params.accountId}/locations/${params.locationId}/localPosts`)
            .then(res => {
                this.setState({
                    localPosts: res.data.localPosts.filter(post =>
                        moment().subtract(3, 'months').isBefore(post.createTime)
                    )
                });
            })
            .finally(() => {
                this.setState({
                    loadingLive: false
                });
            });
    }

    renewPost = async () => {
        this.setState({ confirmLoading: true });

        let method = 'post';
        let url = `${API_BASE_URL}gmb/accounts/${this.props.match.params.accountId}/locations/${this.props.match.params.locationId}/localPosts`;
        let successMessage = 'Submitted post for proofing!';

        axios({
            method,
            url,
            // headers: authHeader(),
            data: this.state.currentlyEditingPost
        })
            .then(async response => {
                // this.props.form.resetFields();
                if (response.data.insertId) {
                    await this.submitComment(response.data.insertId, 'Renewed Post Proof', 'CREATE_PROOF');
                    if (this.state.comment) {
                        await this.submitComment(response.data.insertId, this.state.comment);
                    }
                }

                this.setState({ editModalVisible: false });
                notification.success({ message: successMessage });
            })
            .catch(error => {
                let errorMessage = 'Error creating post!';
                if (this.props.post) {
                    errorMessage = 'Error updating post!';
                }

                console.error(errorMessage, error);
                notification.error({ message: errorMessage });
            })
            .finally(() => {
                this.setState({ confirmLoading: false, comment: '' });
                if (this.props.onSubmit) {
                    this.props.onSubmit();
                }
            });
    };

    submitComment = (proofId, content, type = 'COMMENT') => {
        return axios.post(`${API_BASE_URL}proofing/media/${proofId}/actions`, {
            user_id: null,
            type,
            content,
            author: this.props.user
        });
        // .then(res => {
        //   this.getComments();
        // })
        // .catch(err => {
        //   // notification.error({
        //   //   message: "An error occurred",
        //   //   description: "Your comment was not submitted."
        //   // });
        // });
    };

    render() {
        const {
            // match,
            backendPosts,
            localPosts,
            token
        } = this.state;

        let user = jwt.decode(token);

        if (this.props.user) {
            user = this.props.user;
        }

        let scheduledPosts = [];

        if (backendPosts) {
            scheduledPosts = backendPosts.filter(post => post.state === 'PUBLISHED');
        }

        let posts = [
            ...scheduledPosts
                .map(post => post.post_body)
                .map(post_body => {
                    // if (post_body && post_body.launch_date) {
                    //     post_body.createTime = post_body.launch_date;
                    // }
                    post_body.createTime = post_body.launch_date;

                    return post_body;
                }),
            ...localPosts
        ];

        // console.log(posts);

        return (
            <React.Fragment>
                <Modal
                    centered
                    visible={this.state.editModalVisible}
                    maskClosable={false}
                    title="Renew Post"
                    okText="Submit Post"
                    onCancel={() => this.setState({ editModalVisible: false, comment: '' })}
                    style={{ paddingTop: 24 }}
                    onOk={this.renewPost}
                    confirmLoading={this.state.confirmLoading}
                    // width={750}
                >
                    {this.state.currentlyEditingPost && <PostPreview post={this.state.currentlyEditingPost} />}
                    <Form.Item
                        label="Instructions for Post Renewal"
                        extra="Include requests such as a specific launch date, copy edits, or other changes."
                        style={{ marginTop: 16 }}
                    >
                        <TextArea
                            autoSize={{ minRows: 2 }}
                            value={this.state.comment}
                            onChange={e => this.setState({ comment: e.target.value })}
                        />
                    </Form.Item>
                </Modal>

                <Alert
                    style={{ marginBottom: 16 }}
                    message="Please see below for all previously published posts and currently scheduled posts. If you 
                want to renew a post for a future month, please click the renew
                button and add any additional comments you want to include. A
                notification will be sent to our team to handle post scheduling
                and updating."
                    type="info"
                    showIcon
                />

                {/* {localPosts && localPosts.length > 0 && ( */}
                <>
                    {this.state.loadingLive ? (
                        <div style={{ textAlign: 'center', marginTop: 64 }}>
                            <Spin indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />} />
                        </div>
                    ) : localPosts && localPosts.length > 0 ? (
                        <>
                            <List
                                itemLayout="horizontal"
                                dataSource={this.state.showOldPosts ? posts : posts.slice(0, initialPostsToDisplay)}
                                renderItem={post => (
                                    // <List.Item>
                                    <ProofItem
                                        key={post.name}
                                        post={post}
                                        user={user}
                                        commentsDisabled
                                        // NOTE: Client ability to renewal old posts disabled because of a business
                                        // decision to handle the renewal step internally.
                                        // onRenew={() => {
                                        //   let {
                                        //     id,
                                        //     name,
                                        //     state,
                                        //     updateTime,
                                        //     createTime,
                                        //     searchUrl,
                                        //     ...postWithoutId
                                        //   } = post;

                                        //   this.setState({
                                        //     editModalVisible: true,
                                        //     confirmLoading: false,
                                        //     currentlyEditingPost: postWithoutId
                                        //   });
                                        // }}
                                    />
                                    // </List.Item>
                                )}
                            />
                            {localPosts.length > initialPostsToDisplay && !this.state.showOldPosts && (
                                <Button
                                    block
                                    size="large"
                                    onClick={() => {
                                        this.setState({ showOldPosts: true });
                                    }}
                                >
                                    Load older posts
                                </Button>
                            )}
                        </>
                    ) : (
                        <Empty
                            description={'You have no published posts'}
                            style={{ margin: '80px 0', height: '100%' }}
                        />
                    )}
                </>
                {/* )} */}
            </React.Fragment>
        );
    }
}
