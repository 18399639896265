import React from 'react';
import { Card, Col, Row } from 'antd';
import axios from 'axios';
import _ from 'lodash';

export default class FacebookAdPreview extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            postStatus: props.post.state,
            approving: false,
            proofingContacts: [],
            comments: [],
            actions: [],
            submitting: false,
            commentValue: '',
            showCommentEditor: false
        };
    }

    componentDidMount() {
        //'EAAhaBmfSC1UBAFmYRY7qdD7s735NiYV4YN9YkZBkji1JF8bNvL7oxCXYgXoed0zpSZAzgjfwjnPjnvPZCCO0agCR6ea9DQqRsS8XZByv5JQbxjv0Er5ZB3CFfdHocnJq8C0rgx6uhAMglMYw32ptsAwY5iCYXKL3OqiaYHsgZB9je0XInfZBZCqt';
        if (this.props.post.body && this.props.post.body.proofItemType === 'SOCIAL') {
            // CLX Marketing Application
            const APP_ID = 2350783371807573;
            const APP_SECRET = '523cb3be38d9daa6e5c8a296e42a3bd1';
            const VERSION = 'v19.0';
            const AD_ACCOUNT_ID = this.props.post.body.facebookAdId;
            const ACCESS_TOKEN =
                'EAAhaBmfSC1UBO2ckGTZCrvFSplOhXAcb372362N9PHB1K0SSv1xy0gaZCf23g274owervgbB987ImZC6NrAhFZCM2NOCiSL7zYHqzg3HBcQIOmR4C9qlyG2LKoLLqY3QqZAGlL3SsmiCHZCEYd9u8w2qFtdEeDRONx2456UrdodoucYWRTHT5KvAZDZD';
            const LONG_LIVED_ACCESS_TOKEN =
                'EAAhaBmfSC1UBABG7QD4rH1ZCTg7ZBhO8oiXtrZB9njgZAoFQsxTgiQxL5Dad1CDRVeCqTSogdZBJofvjYWD1W2lu8X8pwQo7RNIukk6yhOd1ZAEYzAwM8oaD1JB7JkrmnR11mr23QEZAEmGU68epbVyRCix9bZADRZB7qbaPxvd5PvFzzS08e25Jz';
            const AD_FORMAT = this.props.post.body.ad_format || 'DESKTOP_FEED_STANDARD' || 'MOBILE_FEED_STANDARD';

            const url = `https://graph.facebook.com/${VERSION}/${AD_ACCOUNT_ID}/previews?ad_format=${AD_FORMAT}&access_token=${ACCESS_TOKEN}`;

            console.log(url);
            /* 
              Call the Facebook API and get the Facebook Ad Preview iframe
              that containes the clients social media ads. Display the iframe
              on the CLX Proofing site for client review.
            */
            axios
                .get(url)
                .then(res => {
                    console.log(res.data.data[0]);

                    this.setState({
                        facebookAdPreview: res.data.data[0]
                    });
                })
                .catch(error => {
                    console.log('META ERROR: ', error);
                });
        }
    }

    render() {
        const { post } = this.props;

        return (
            <div
                style={{
                    textAlign: 'left',
                    margin: 'auto',
                    maxWidth: 1400,
                    borderRadius: 2,
                    marginBottom: 16
                }}
                key={post.id}
                id={post.id}
            >
                <Row type="flex" justify="center" gutter={32}>
                    <Col>
                        <Card
                            style={{
                                backgroundColor:
                                    post.body && post.body.proofItemType === 'SOCIAL' ? null : 'rgb(240, 242, 245)',
                                boxShadow:
                                    post.body && post.body.proofItemType === 'SOCIAL'
                                        ? null
                                        : 'inset 0 2px 4px 0 rgba(0,0,0,.06)',
                                height: '100%'
                            }}
                            bodyStyle={{ paddingLeft: 8, paddingRight: 8 }}
                        >
                            <div
                                style={{
                                    borderRadius: 2,
                                    maxWidth:
                                        post.body &&
                                        (post.body.emailPreviewLink || post.body.proofItemType === 'SOCIAL')
                                            ? null
                                            : 500,
                                    margin: 'auto',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}
                            >
                                <div
                                    style={{
                                        alignSelf: 'center',
                                        maxWidth: '100%',
                                        boxShadow:
                                            post.body && post.body.proofItemType === 'SOCIAL'
                                                ? null
                                                : '0 4px 6px -1px rgba(0,0,0,.1),0 2px 4px -1px rgba(0,0,0,.06)'
                                    }}
                                >
                                    {post.body && post.body.proofItemType === 'SOCIAL' && (
                                        <React.Fragment>
                                            {this.state.facebookAdPreview && (
                                                <div
                                                    style={{
                                                        display: 'inline-block',
                                                        marginRight: 'auto',
                                                        marginLeft: 'auto'
                                                    }}
                                                    dangerouslySetInnerHTML={{
                                                        __html: this.state.facebookAdPreview.body
                                                            .replace(' scrolling="yes"', ' scrolling="no"')
                                                            .replace(' width="540"', ' width="502"')
                                                            .replace(' height="690"', ' height="815"')
                                                    }}
                                                />
                                            )}
                                        </React.Fragment>
                                    )}
                                </div>
                            </div>
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}
