module.exports = {
  GMB_BASE_API: "/gmb/locations/",
  DATE_STRING_FORMAT: "YYYY-MM-DD",
  DATA_TYPE_INDEX: {
    DataTableHeaders: 0,
    Data: 1
  },
  CATEGORIES_SUPPORT_APPOINTMENT_URL: [
    "gcid:apartment_rental_agency",
    "gcid:apartment_complex",
    "gcid:apartment_building",
    "gcid:assisted_living_facility",
    "gcid:retirement_community"
  ],
  LocalPostTopicType: {
    STANDARD: "What's new",
    EVENT: "Event",
    OFFER: "Offer"
  },
  ActionType: {
    ACTION_TYPE_UNSPECIFIED: "None",
    BOOK: "Book",
    ORDER: "Order online",
    SHOP: "Buy",
    LEARN_MORE: "Learn more",
    SIGN_UP: "Sign up",
    CALL: "Call now"
  }
};
