import React from 'react';

import {
    Avatar,
    Badge,
    Button,
    Card,
    Col,
    Comment,
    Dropdown,
    Form,
    Icon,
    Input,
    Menu,
    Modal,
    notification,
    Popconfirm,
    Row,
    Skeleton,
    Tooltip
} from 'antd';

import axios from 'axios';
import moment from 'moment';
import _ from 'lodash';

import PostPreview from './PostPreview';
import EmailPreview from '../EmailPreview';
import CommentList from './CommentList';
import GoogleSearchAd from '../GoogleSearchAd';

// import { Document, Page } from "react-pdf";

import { API_BASE_URL } from './variables/Variables';
import getAvatarColor from './getAvatarColor';

const { TextArea } = Input;

const avatarShape = 'circle';
const avatarFontSize = 16;
const agencyName = 'Conversion Logix';
const agencyAvatarUrl = 'https://conversionlogix.com/images/logo/cl-avatar.png';

const Editor = ({ onChange, onSubmit, submitting, value }) => (
    <div className="comment-editor">
        <Form.Item style={{ marginBottom: 12 }}>
            <TextArea
                rows={3}
                onChange={onChange}
                value={value}
                placeholder="Type your comments or request for revision"
            />
        </Form.Item>
        <Form.Item style={{ marginBottom: 0 }}>
            <Button htmlType="submit" loading={submitting} onClick={onSubmit} type="primary">
                Add Comment
            </Button>
        </Form.Item>
    </div>
);

let ProofStatusIndicator = props => {
    if (!props.proofingContacts) {
        return null;
    }

    let approvals = props.comments.filter(comment => comment.type === 'APPROVAL' && comment.author);
    approvals = _.uniqBy(approvals, 'author.email');

    let contacts = _.sortBy(props.proofingContacts, 'name').filter(contact => !contact.email.includes('@clxmedia.com'));

    let awaitingCount = contacts.length - approvals.length;

    return (
        <div style={{ marginBottom: 16 }}>
            <div style={{ marginBottom: 8 }}>
                <strong>Approvals</strong>
                <br />
                <span>
                    {approvals.length} approved
                    {awaitingCount > 0 && `, ${awaitingCount} awaiting`}
                </span>
            </div>
            {contacts &&
                contacts.map((contact, i) => (
                    <div key={i} style={{ marginRight: 8 + 7, marginBottom: 8 }}>
                        {/* <Tooltip title={comment.author}> */}
                        {approvals.find(approval => approval.author.email === contact.email) ? (
                            <React.Fragment>
                                <Badge
                                    count={
                                        <Icon
                                            type="check-circle"
                                            theme="filled"
                                            style={{
                                                color: '#52c41a',
                                                backgroundColor: 'white',
                                                border: '2px solid white',
                                                borderRadius: '100%'
                                            }}
                                        />
                                    }
                                    // offset={[0, 32 - 7]}
                                    offset={[0, 28 - 7]}
                                >
                                    <Avatar
                                        shape={avatarShape}
                                        size={28}
                                        // icon="user"
                                        style={{
                                            fontSize: avatarFontSize,
                                            backgroundColor: contact.email ? getAvatarColor(contact.email) : null
                                        }}
                                    >
                                        {contact.name.substr(0, 1)}
                                    </Avatar>
                                </Badge>
                                <span style={{ marginLeft: 8 + 7 }}>{contact.name}</span>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <Avatar
                                    shape={avatarShape}
                                    size={28}
                                    // icon="user"
                                    style={{
                                        fontSize: avatarFontSize,
                                        backgroundColor: contact.email ? getAvatarColor(contact.email) : null
                                    }}
                                >
                                    {contact.name.substr(0, 1)}
                                </Avatar>
                                <span style={{ marginLeft: 8 + 7 }}>{contact.name}</span>
                            </React.Fragment>
                        )}
                        {/* </Tooltip> */}
                    </div>
                ))}
        </div>
    );
};

/*
    #############################################
    TODO: remove this component
    
    This appears to be abandonded code.
    The component for previewing Facebook Iframes is FacebookAdPreviews.jsx
    #############################################
*/
export default class ProofItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            postStatus: props.post.state, // || "PROOFING",
            approving: false,
            proofingContacts: [],
            comments: [],
            actions: [],
            submitting: false,
            commentValue: '',
            showCommentEditor: false
        };
    }

    componentDidMount() {
        this.getComments();

        if (!this.props.commentsDisabled) {
            const MILLISECONDS_BETWEEN_COMMENT_REFRESH = 10 * 1000;
            this.activityRefreshInterval = setInterval(() => {
                if (!document.hidden) {
                    this.getComments();
                }
            }, MILLISECONDS_BETWEEN_COMMENT_REFRESH);
        }

        if (this.props.post.body && this.props.post.body.proofItemType === 'SOCIAL') {
            console.log('GET IFRAME!');
            // facebookAdPreview
            axios
                .get(
                    `https://graph.facebook.com/v10.0/${this.props.post.body.facebookAdId}/previews?ad_format=${
                        this.props.post.body.ad_format || 'DESKTOP_FEED_STANDARD' || 'MOBILE_FEED_STANDARD'
                    }&access_token=EAAhaBmfSC1UBABZCUl6RIRAFsZB67abmoO4JFzpgVSjzEhCZBAripUPJASsWJEscHGItZBcU8td9qoqYyqHPTPwhPjyOhaqgDbls5vLWwBdeDUeoZCPzPbxpa97H4dRd2cm3ZAEZBPWgkGRlkPeENlSmODue1Luc0OHe0e70NaBZCaZBKE9qZBWQ7t`
                )
                .then(res => {
                    console.log(res.data.data[0]);

                    this.setState({
                        facebookAdPreview: res.data.data[0]
                    });
                });
        }
    }

    componentWillUnmount() {
        clearInterval(this.activityRefreshInterval);
    }

    getComments() {
        axios
            .get(`${API_BASE_URL}proofing/media/${this.props.post.id}/actions`)
            .then(res => {
                if (res.data.actions.length) {
                    this.setState({
                        comments: res.data.actions.map(action => {
                            let author = '';
                            let authorInitial;
                            let avatarProps = {
                                shape: avatarShape,
                                // icon: "user",
                                style: { backgroundColor: '#87d068' }
                            };

                            if (action.author) {
                                author = action.author.name || action.author.email;

                                if (action.author.email) {
                                    avatarProps.style.backgroundColor = getAvatarColor(action.author.email);
                                }

                                if (action.author.isAgency) {
                                    avatarProps.src = agencyAvatarUrl;
                                    avatarProps.style.backgroundColor = '#b60612';
                                    author = agencyName;
                                } else {
                                    authorInitial = author.substr(0, 1);
                                    if (!authorInitial) {
                                        avatarProps.icon = 'user';
                                    }
                                }
                            }

                            let content = action.content;
                            if (action.type !== 'COMMENT') {
                                content = <em>{content}</em>;
                                if (action.type === 'APPROVAL') {
                                    content = (
                                        <React.Fragment>
                                            <Icon
                                                type="check"
                                                style={{
                                                    color: '#52c41a'
                                                }}
                                            />{' '}
                                            {content}
                                        </React.Fragment>
                                    );
                                } else if (action.type === 'CREATE_PROOF') {
                                    content = (
                                        <React.Fragment>
                                            <Icon
                                                type="file-add"
                                                style={{
                                                    color: '#aaaaaa'
                                                }}
                                            />{' '}
                                            {content}
                                        </React.Fragment>
                                    );
                                } else if (action.type === 'REVISE_PROOF') {
                                    content = (
                                        <React.Fragment>
                                            <Icon
                                                type="undo"
                                                style={{
                                                    color: '#aaaaaa'
                                                }}
                                            />{' '}
                                            {content}
                                        </React.Fragment>
                                    );
                                }
                            }

                            return {
                                ...action,
                                avatar: authorInitial ? (
                                    <Avatar {...avatarProps}>{authorInitial}</Avatar>
                                ) : (
                                    <Avatar {...avatarProps} />
                                ),
                                datetime: (
                                    <span title={moment(action.timestamp).format('dddd, MMMM Do YYYY, h:mma')}>
                                        {moment(action.timestamp).fromNow()}
                                    </span>
                                ),
                                author,
                                content
                            };
                        }),
                        actions: res.data.actions
                    });
                }
            })
            .catch(err => {
                console.log(err);
            })
            .finally(e => {
                this.setState({
                    loading: false
                });
            });
    }

    handleApprove(post) {
        this.setState({ approving: true });
        console.log('approving post:', post);

        let postBody = post.post_body || post.body || post;
        postBody.state = 'APPROVED';
        postBody.launch_date = post.launch_date;
        postBody.author = this.props.user;

        let url = !post.locationId
            ? `${API_BASE_URL}proofing/company/${post.odoo_entity_id}/proofItems/${post.id}`
            : `${API_BASE_URL}gmb/accounts/${post.odoo_entity_id}/locations/${post.locationId}/localPosts/${post.id}`;

        axios
            .patch(url, postBody)
            .then(res => {
                notification.success({
                    message: `Post ID ${post.id} was Approved!`
                });
                this.setState({ postStatus: 'APPROVED' });
                this.getComments();
            })
            .catch(err => {
                notification.error({
                    message: 'There was an error approving the post'
                });
            })
            .finally(() => {
                this.setState({ approving: false });
                if (this.props.onApprove) {
                    this.props.onApprove();
                }
            });
    }

    handleSubmit = () => {
        if (!this.state.commentValue) {
            return;
        }

        this.setState({
            submitting: true
        });

        axios
            .post(`${API_BASE_URL}proofing/media/${this.props.post.id}/actions`, {
                user_id: null,
                type: 'COMMENT',
                content: this.state.commentValue,
                author: this.props.user
            })
            .then(res => {
                // if (res.data.actions.length) {
                this.setState({
                    commentValue: ''
                });
                // }
                this.getComments();
            })
            .catch(err => {
                notification.error({
                    message: 'An error occurred',
                    description: 'Your comment was not submitted.'
                });
            })
            .finally(() => {
                this.setState({
                    submitting: false
                });
            });
    };

    handleChange = e => {
        this.setState({
            commentValue: e.target.value
        });
    };

    render() {
        const { post } = this.props;
        const { approving, postStatus, comments, loading, submitting, commentValue } = this.state;

        let likes = 2;
        let dislikes = 0;

        const actions = [
            <span key="comment-basic-like">
                <Tooltip title="Like">
                    <Icon type="like" />
                </Tooltip>
                <span style={{ paddingLeft: 8, cursor: 'auto' }}>{likes}</span>
            </span>,
            <span key=' key="comment-basic-dislike"'>
                <Tooltip title="Dislike">
                    <Icon type="dislike" />
                </Tooltip>
                <span style={{ paddingLeft: 8, cursor: 'auto' }}>{dislikes}</span>
            </span>,
            <span key="comment-basic-reply-to">Reply to</span>
        ];

        return (
            <div
                style={{
                    textAlign: 'left',
                    margin: 'auto',
                    maxWidth: 1400,
                    boxShadow: '0 1px 3px 0 rgba(0,0,0,.1),0 1px 2px 0 rgba(0,0,0,.06)',
                    borderRadius: 2
                }}
                key={post.id}
                id={post.id}
            >
                <Card style={{ marginBottom: 16 }}>
                    <Row
                        type="flex"
                        justify="center"
                        gutter={32}
                        // align="top"
                    >
                        <Col xs={24} md={!this.props.commentsDisabled ? 16 : 24}>
                            <Card
                                style={{
                                    backgroundColor:
                                        post.body && post.body.proofItemType === 'SOCIAL' ? null : 'rgb(240, 242, 245)',
                                    boxShadow:
                                        post.body && post.body.proofItemType === 'SOCIAL'
                                            ? null
                                            : 'inset 0 2px 4px 0 rgba(0,0,0,.06)',
                                    height: '100%'
                                }}
                                bodyStyle={{ paddingLeft: 8, paddingRight: 8 }}
                            >
                                <div
                                    style={{
                                        borderRadius: 2,
                                        maxWidth:
                                            post.body &&
                                            (post.body.emailPreviewLink || post.body.proofItemType === 'SOCIAL')
                                                ? null
                                                : 500,
                                        margin: 'auto',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}
                                >
                                    <div
                                        style={{
                                            alignSelf: 'center',
                                            maxWidth: '100%',
                                            boxShadow:
                                                post.body && post.body.proofItemType === 'SOCIAL'
                                                    ? null
                                                    : '0 4px 6px -1px rgba(0,0,0,.1),0 2px 4px -1px rgba(0,0,0,.06)'
                                        }}
                                    >
                                        {post.body && post.body.emailPreviewLink ? (
                                            <EmailPreview previewLink={post.body.emailPreviewLink} />
                                        ) : post.body && post.body.proofItemType === 'DISPLAY' ? (
                                            <React.Fragment>
                                                <a
                                                    onClick={() => {
                                                        this.setState({
                                                            lightBoxImage: post.body.media[0].sourceUrl
                                                        });
                                                    }}
                                                >
                                                    <img
                                                        src={post.body.media[0].sourceUrl}
                                                        alt=""
                                                        style={{ width: '100%' }}
                                                    />
                                                </a>
                                                <Modal
                                                    visible={!!this.state.lightBoxImage}
                                                    footer={null}
                                                    bodyStyle={{ padding: 0 }}
                                                    // closeIcon={<Icon type="close" />}
                                                    onCancel={() => {
                                                        this.setState({ lightBoxImage: null });
                                                    }}
                                                    width={800}
                                                >
                                                    <img
                                                        src={post.body.media[0].sourceUrl}
                                                        alt=""
                                                        style={{ width: '100%' }}
                                                    />
                                                </Modal>
                                            </React.Fragment>
                                        ) : post.body && post.body.proofItemType === 'SOCIAL' ? (
                                            <React.Fragment>
                                                {this.state.facebookAdPreview && (
                                                    <div
                                                        style={{
                                                            display: 'inline-block',
                                                            marginRight: 'auto',
                                                            marginLeft: 'auto'
                                                        }}
                                                        dangerouslySetInnerHTML={{
                                                            __html: this.state.facebookAdPreview.body
                                                                .replace(' scrolling="yes"', ' scrolling="no"')
                                                                .replace(' width="540"', ' width="502"')
                                                                .replace(' height="690"', ' height="815"')
                                                            // '<iframe src="http://www.facebook.com/ads/api/preview_iframe.php?d=AQKv8HK_TFXmMgE4_XQiXQiSQmWiz1RgyJmv3lzMeaWi2aXriLIyvlScqhNY80xspHeDxzYALoyJ0ei2-u-VGCp3qcqjcZc5dkDRGPsl6vBhu9fJh2LIo4ZslI5Mb49k-bNe2WXQvxO69s-_AysOnM8puog39SXBAlC7qIs78P64gyTlia9mUyKMaPlJcgkzbygQDclRRCoG-YKx3Kg_CcxhAbJsFCbGDo1gIE2croRANJArAcSH94HpD-xeoKJjnb3yNuQXQGF-6JQVJXpyPUmNDHSpay_OyKASUs_9cZQEo5GeSoB6sqkPSwRnk_pM5Lgl9hHvOy9G5EKeg18JTmB9&amp;t=AQIlIeTtNSJgp3PE" width="320" height="525" scrolling="no" style="border: none;"></iframe>'
                                                        }}
                                                    />
                                                )}
                                            </React.Fragment>
                                        ) : post.body && post.body.proofItemType === 'SEARCH' ? (
                                            <GoogleSearchAd
                                                ad={{
                                                    url: 'www.infinitycapitolhillapartments.com/',
                                                    headline_1: '708 Uptown Apartments',
                                                    headline_2: 'Apts for Rent Seattle, WA',
                                                    headline_3: 'Elegant & Modern Finishes',
                                                    description_line_1:
                                                        'This Modern Community Is Decked out With Elegant Amenities! Schedule a Tour Today!',
                                                    description_line_2:
                                                        'Spacious One & Two Bedroom Apartments With Modern Designer Finishes!',
                                                    phone: '(206) 203-5832',
                                                    callouts: [
                                                        {
                                                            title: 'Pets',
                                                            url: 'https://www.infinitycapitolhillapartments.com/wa/seattle/pets.aspx'
                                                        },
                                                        {
                                                            title: 'Floor Plans',
                                                            url: 'https://www.infinitycapitolhillapartments.com/'
                                                        },
                                                        {
                                                            title: 'Gallery',
                                                            url: 'https://www.infinitycapitolhillapartments.com/'
                                                        },
                                                        {
                                                            title: 'Amenities',
                                                            url: 'https://www.infinitycapitolhillapartments.com/'
                                                        },
                                                        {
                                                            title: 'Location',
                                                            url: 'https://www.infinitycapitolhillapartments.com/'
                                                        }
                                                    ]
                                                }}
                                            />
                                        ) : (
                                            <PostPreview post={post.body || post.post_body || post} />
                                        )}
                                    </div>
                                </div>
                            </Card>
                        </Col>
                        {!this.props.commentsDisabled && (
                            <Col xs={24} md={8} className="proof-comments">
                                <Skeleton loading={loading} active avatar>
                                    {comments.length > 0 && (
                                        <React.Fragment>
                                            {this.props.proofingContacts && (
                                                <React.Fragment>
                                                    <ProofStatusIndicator
                                                        proofingContacts={this.props.proofingContacts}
                                                        comments={this.state.actions}
                                                    />
                                                    <strong>Activity</strong>
                                                </React.Fragment>
                                            )}
                                            <CommentList comments={comments} actions={actions} />
                                        </React.Fragment>
                                    )}
                                </Skeleton>
                                <Comment
                                    avatar={
                                        <Avatar
                                            shape={avatarShape}
                                            icon="user"
                                            style={{
                                                backgroundColor: this.props.user
                                                    ? getAvatarColor(this.props.user.email)
                                                    : null
                                            }}
                                        />
                                    }
                                    content={
                                        <Editor
                                            onChange={this.handleChange}
                                            onSubmit={this.handleSubmit}
                                            submitting={submitting}
                                            value={commentValue}
                                            style={{ marginBottom: -36 }}
                                        />
                                    }
                                />
                            </Col>
                        )}
                    </Row>
                    <div style={{ marginTop: 16 }}>
                        <Row>
                            <Col span={16}>
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        width: '100%'
                                    }}
                                >
                                    {post.state === 'PROOFING' ? (
                                        <span>
                                            <Popconfirm
                                                title="Are you sure you want to approve and publish this post?"
                                                onConfirm={() => this.handleApprove(post)}
                                                okText="Yes"
                                                cancelText="No"
                                                placement="topLeft"
                                                overlayStyle={{ maxWidth: 250 }}
                                            >
                                                <Button
                                                    type="primary"
                                                    size="large"
                                                    style={{ marginRight: 8 }}
                                                    loading={approving}
                                                    className="approve-button green-button"
                                                >
                                                    Approve
                                                </Button>
                                            </Popconfirm>
                                            {/* TODO: Enable "request changes" link and modal after integrating renewals */}

                                            {post.proof_type === 'GMB' && (
                                                <em>
                                                    {post.launch_date ? (
                                                        <span>
                                                            post will be scheduled to launch{' '}
                                                            <span
                                                                title={moment(post.launch_date).format(
                                                                    'dddd, MMMM Do YYYY, LT' // "dddd, MMMM Do YYYY, h:mma"
                                                                )}
                                                            >
                                                                {moment(post.launch_date).format('L [at] LT')}
                                                            </span>
                                                        </span>
                                                    ) : (
                                                        `post will launch immediately`
                                                    )}
                                                </em>
                                            )}
                                        </span>
                                    ) : post.state === 'APPROVED' ? (
                                        <span style={{ fontSize: 'large' }}>
                                            <Icon
                                                type="check-circle"
                                                theme="twoTone"
                                                twoToneColor="#52c41a"
                                                style={{ marginRight: 6 }}
                                            />
                                            Approved
                                            {post.launch_date && (
                                                <span>
                                                    , scheduled to launch{' '}
                                                    <span
                                                        title={moment(post.launch_date).format(
                                                            'dddd, MMMM Do YYYY, LT' // "dddd, MMMM Do YYYY, h:mma"
                                                        )}
                                                    >
                                                        {moment(post.launch_date).format('L')}
                                                    </span>
                                                </span>
                                            )}
                                        </span>
                                    ) : post.state === 'PUBLISHED' ? (
                                        <span style={{ fontSize: 'large' }}>
                                            <Icon
                                                type="calendar"
                                                theme="twoTone"
                                                twoToneColor="#faad14"
                                                style={{
                                                    // color: "#faad14",
                                                    marginRight: 6
                                                }}
                                            />
                                            Scheduled to launch{' '}
                                            <span
                                                title={moment(post.launch_date).format(
                                                    'dddd, MMMM Do YYYY, LT' // "dddd, MMMM Do YYYY, h:mma"
                                                )}
                                            >
                                                {moment(post.launch_date).format('L')}
                                            </span>
                                        </span>
                                    ) : null}
                                    <div style={{ marginRight: 10 }}>
                                        <em>post id {post.id}</em>
                                    </div>
                                </div>
                            </Col>
                            <Col span={8} style={{ textAlign: 'right' }}>
                                {this.props.onEdit && (
                                    <Button
                                        icon="edit"
                                        type="link"
                                        size="large"
                                        style={{ padding: 0 }}
                                        onClick={this.props.onEdit}
                                    >
                                        Edit
                                    </Button>
                                )}
                                {this.props.onDuplicate && (
                                    <Button
                                        icon="copy"
                                        type="link"
                                        size="large"
                                        style={{ padding: 0, marginLeft: 16 }}
                                        onClick={this.props.onDuplicate}
                                    >
                                        Duplicate
                                    </Button>
                                )}
                                {this.props.onRenew && (
                                    <Tooltip title="Renew your post to launch next month.">
                                        <Button
                                            icon="sync"
                                            // type="link"
                                            type="primary"
                                            size="large"
                                            // style={{ padding: 0, marginLeft: 16 }}
                                            onClick={this.props.onRenew}
                                        >
                                            Renew Post
                                        </Button>
                                    </Tooltip>
                                )}
                                {/* {this.props.onDelete && (
                  <Button
                    icon="delete"
                    type="link"
                    size="large"
                    style={{ padding: 0, marginLeft: 16, color: "#ff4d4f" }}
                    onClick={() => {
                      confirm({
                        centered: true,
                        title: "Are you sure you want to delete this post?",
                        content:
                          "It cannot be recovered after it has been deleted.",
                        icon: "delete",
                        onOk: () => {
                          this.props.onDelete();
                        },
                        okText: "Delete",
                        okButtonProps: { type: "danger" }
                      });
                    }}
                  >
                    Delete
                  </Button>
                )} */}
                                {!this.props.onRenew && this.props.post && this.props.post.searchUrl && (
                                    <Dropdown
                                        overlay={
                                            <Menu
                                            // onClick={handleMenuClick}
                                            >
                                                <Menu.Item key="view">
                                                    <a
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        href={this.props.post.searchUrl}
                                                    >
                                                        View post on Search
                                                    </a>
                                                </Menu.Item>
                                                {/* <Menu.Item key="2">2nd item</Menu.Item>
                                            <Menu.Item key="3">3rd item</Menu.Item> */}
                                            </Menu>
                                        }
                                        placement="topRight"
                                    >
                                        <Button
                                            icon="more"
                                            type="link"
                                            size="large"
                                            style={{ padding: 0, marginLeft: 16 }}
                                            // onClick={this.props.onDuplicate}
                                        />
                                    </Dropdown>
                                )}
                            </Col>
                        </Row>
                    </div>
                </Card>
            </div>
        );
    }
}
