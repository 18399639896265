import React from 'react';
import { Comment, List } from 'antd';

const CommentList = ({ comments }) => (
    <List
        dataSource={comments}
        // header="Activity"
        itemLayout="horizontal"
        renderItem={props => <Comment {...props} />}
    />
);

export default CommentList;
