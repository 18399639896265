import React, { useEffect, useState } from "react";
import axios from "axios";
import { Avatar, Card, Comment } from "antd";
import { API_BASE_URL } from "./GoogleMyBusiness/variables/Variables";

export default props => {
  const [emailPreview, setEmailPreview] = useState();

  function getData() {
    axios
      .get(API_BASE_URL + "emailPreview?url=" + props.previewLink)
      .then(res => {
        setEmailPreview(res.data);
      });
  }

  useEffect(() => {
    getData();
  }, []);

  const emailContent = emailPreview && (
    <React.Fragment>
      <div
        style={{ marginTop: 16 }}
        dangerouslySetInnerHTML={{
          __html: emailPreview.html
          // '<iframe src="http://www.facebook.com/ads/api/preview_iframe.php?d=AQKv8HK_TFXmMgE4_XQiXQiSQmWiz1RgyJmv3lzMeaWi2aXriLIyvlScqhNY80xspHeDxzYALoyJ0ei2-u-VGCp3qcqjcZc5dkDRGPsl6vBhu9fJh2LIo4ZslI5Mb49k-bNe2WXQvxO69s-_AysOnM8puog39SXBAlC7qIs78P64gyTlia9mUyKMaPlJcgkzbygQDclRRCoG-YKx3Kg_CcxhAbJsFCbGDo1gIE2croRANJArAcSH94HpD-xeoKJjnb3yNuQXQGF-6JQVJXpyPUmNDHSpay_OyKASUs_9cZQEo5GeSoB6sqkPSwRnk_pM5Lgl9hHvOy9G5EKeg18JTmB9&amp;t=AQIlIeTtNSJgp3PE" width="320" height="525" scrolling="no" style="border: none;"></iframe>'
        }}
      />
    </React.Fragment>
  );

  return emailPreview ? (
    <Card bodyStyle={{ padding: "24px 16px" }}>
      <div
        style={{
          fontSize: 18
          // marginLeft: 32 + 12
        }}
      >
        <strong>{emailPreview.subject}</strong>
      </div>
      <Comment
        author={
          <span style={{ fontSize: 14 }}>
            <strong>Conversion Logix</strong> {"<info@clxmedia.com>"}
          </span>
        }
        avatar={<Avatar />}
        // content={emailContent}
      />
      <div style={{ marginTop: -24 }}>{emailContent}</div>
    </Card>
  ) : null;
};
