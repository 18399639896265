import React from "react";
import { Alert, Row, Checkbox, Col, Button, Empty, Icon, List, PageHeader, Form, Input, Typography } from "antd";

import Tour from "reactour";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";

import FacebookAdPreview from "./FacebookAdPreview";

import LoadingIndicator from "./LoadingIndicator";

const { Paragraph } = Typography;

const SOCIAL_PREVIEW_BASE_URL = "http://proofing.conversionlogix.com/proofs/social";

const SUPPORTED_FORMATS = [
  {
    value: "DESKTOP_FEED_STANDARD",
    title: "Desktop News Feed",
    icon: "picture",
  },
  {
    value: "INSTAGRAM_STORY",
    title: "Instagram Story",
    icon: "instagram",
  },
  {
    value: "INSTAGRAM_STANDARD",
    title: "Instagram Feed",
    icon: "instagram",
  },
];

const FacebookPreviewLinkGenerator = (props) => {
  const { getFieldDecorator, getFieldValue } = props.form;

  let ids = [];
  SUPPORTED_FORMATS.forEach((format) => {
    let value = getFieldValue(format.value);
    if (value) {
      ids.push({ format: format.value, value: value });
    }
  });

  let queryString = "";

  if (ids.length) {
    queryString += "?";
    queryString += ids.map((formatIds) => formatIds.format + "=" + formatIds.value).join("&");
  }

  return (
    <div style={{ height: "100%" }}>
      <div className={"content"} style={{ height: "100%", padding: "0 16px", marginBottom: 16 }}>
        <Row>
          <Col>
            <PageHeader title="Facebook Ads Preview Link Generator" />
            <div style={{ maxWidth: 1000, margin: "16px auto 0" }}>
              <div
                style={{
                  // textAlign: "center",
                  height: "100%",
                }}
              >
                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                    props.form.validateFields((err, values) => {
                      if (!err) {
                        props.onLogin(values);
                      }
                    });
                  }}
                  // onSubmit={this.handleSubmit}
                  className="login-form"
                >
                  {SUPPORTED_FORMATS.map((format) => (
                    <Form.Item key={format.value}>
                      {getFieldDecorator(format.value, {
                        // rules: [{ required: true, message: "Please enter your name" }],
                      })(
                        <Input
                          size="large"
                          prefix={<Icon type={format.icon} style={{ color: "rgba(0,0,0,.25)" }} />}
                          placeholder={`${format.title} Ad Ids`}
                        />
                      )}
                    </Form.Item>
                  ))}
                </Form>
                <Paragraph copyable strong>
                  {SOCIAL_PREVIEW_BASE_URL + queryString}
                </Paragraph>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Form.create({ name: "fb-link-generator" })(FacebookPreviewLinkGenerator);
