import React from "react";
import { Button, Collapse, Card, Icon } from "antd";

import GMB_CONSTANTS from "./constants/google-my-business.constants";
import moment from "moment";

import "./GMBPosts.css";

export default class PostPreview extends React.Component {
  render() {
    const { post } = this.props;

    if (!post) {
      return null;
    }

    let media = null;

    if (post.media) {
      media = post.media[0];
    }

    let actionType = null;
    if (post.callToAction) {
      actionType = GMB_CONSTANTS.ActionType[post.callToAction.actionType];
    }

    return (
      <Card
        className="gmb-post-card"
        // hoverable
        cover={
          media && (
            // media.mediaFormat == 'PHOTO' ? (
            <img
              alt="Post cover"
              src={media.googleUrl || media.sourceUrl}
              // style={{ height: 405 }}
            />
          )
          //  : media.mediaFormat == 'VIDEO' ? (
          //     <video>
          //         <source src={media.googleUrl} />
          //     </video>
          // ) : null)
        }
      >
        {post.event && (
          <React.Fragment>
            <Card.Meta
              title={
                (post.event && post.event.title === "COVID-19") || !post.media ? (
                  <span>
                    <Icon style={{ color: "#f5222d" }} type="warning" theme="outlined" />
                    &nbsp;COVID-19 update
                  </span>
                ) : (
                  <span>
                    {post.event.title}
                    {post.offer && <Icon className="offer-tag-icon" type="tag" theme="filled" rotate={-90} />}
                  </span>
                )
              }
              description={
                post.event.schedule &&
                (post.offer ? "Valid: " : "") +
                  moment({
                    ...post.event.schedule.startDate,
                    month: post.event.schedule.startDate.month - 1,
                  }).format("MMM D") +
                  " – " +
                  moment({
                    ...post.event.schedule.endDate,
                    month: post.event.schedule.endDate.month - 1,
                  }).format("MMM D")
              }
            />
            <br />
          </React.Fragment>
        )}
        {post.updateTime && (
          <div className="gmb-post-date-posted" title={moment(post.updateTime).format("dddd, MMMM Do YYYY, h:mma")}>
            Posted {moment(post.updateTime).fromNow()}
          </div>
        )}

        <div>
          {post.summary &&
            post.summary.split("\n").map((i, key) => {
              return <p key={key}>{i}</p>;
            })}
        </div>

        {post.callToAction && (
          <React.Fragment>
            <br />
            <Button type="primary" href={post.callToAction.url} target="_blank">
              {actionType}
            </Button>
          </React.Fragment>
        )}

        {post.offer && (
          <React.Fragment>
            <br />
            {post.offer.redeemOnlineUrl && (
              <a href={post.offer.redeemOnlineUrl} target="_blank" rel="noopener noreferrer">
                Redeem online
              </a>
            )}
            <Card style={{ textAlign: "center" }} className="gmb-post-offer-box">
              {post.offer.couponCode && (
                <React.Fragment>
                  Show this code at the store
                  <br />
                  <strong className="gmb-post-offer-code">{post.offer.couponCode}</strong>
                  <br />
                </React.Fragment>
              )}
              {"Valid " +
                moment({
                  ...post.event.schedule.startDate,
                  month: post.event.schedule.startDate.month - 1,
                }).format("MM/D/YYYY") +
                " – " +
                moment({
                  ...post.event.schedule.endDate,
                  month: post.event.schedule.endDate.month - 1,
                }).format("MM/D/YYYY")}
            </Card>
            {post.offer.termsConditions && (
              <Collapse
                bordered={false}
                //  onChange={callback}
              >
                <Collapse.Panel header="Terms and Conditions" style={{ borderBottom: "none" }}>
                  {post.offer.termsConditions}
                </Collapse.Panel>
              </Collapse>
            )}
          </React.Fragment>
        )}
      </Card>
    );
  }
}
