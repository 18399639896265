import React from "react";
import { Alert, Row, Col, Button, Empty, List, PageHeader } from "antd";

import Tour from "reactour";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";

import axios from "axios";
import jwt from "jsonwebtoken";
import queryString from "query-string";

import ProofItem from "./GoogleMyBusiness/ProofItem";
import FacebookAdPreview from "./FacebookAdPreview";

import LoadingIndicator from "./LoadingIndicator";

const SUPPORTED_FORMATS = [
  {
    value: "DESKTOP_FEED_STANDARD",
    title: "Desktop News Feed",
    icon: "picture",
  },
  {
    value: "INSTAGRAM_STORY",
    title: "Instagram Story",
    icon: "instagram",
  },
  {
    value: "INSTAGRAM_STANDARD",
    title: "Instagram Feed",
    icon: "instagram",
  },
];

export default class FacebookPreviewPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      accountId: null,
      locationId: null,
      locationData: null,
      posts: null,
      backendPosts: null,
      token: null,
      isTourOpen: false,
      activeTab: props.match.params.state || "proofing",
      loadingInternal: true,
      loadingLive: true,
    };
  }

  componentDidMount() {
    const {
      match: { params },
    } = this.props;
    const queryParameters = queryString.parse(this.props.location.search);

    console.log(queryParameters);

    let ad_ids = [];

    SUPPORTED_FORMATS.forEach((format) => {
      let format_ids =
        queryParameters &&
        queryParameters[format.value] &&
        queryParameters[format.value].split(",").map((id) => ({ id, format: format.value }));
      if (format_ids) {
        ad_ids = ad_ids.concat(format_ids);
      }
    });

    console.log(ad_ids);

    this.setState({ facebookAdIds: ad_ids, loadingInternal: false });

    // console.log(jwt.decode(queryParameters.token));

    // this.setState({ ...params, token: queryParameters.token });
  }

  startTour = () => {
    this.disableBody();
    this.setState({ isTourOpen: true });
  };

  disableBody = (target) => disableBodyScroll(target);
  enableBody = (target) => enableBodyScroll(target);

  render() {
    const {
      // match,
      backendPosts,
      localPosts,
      locationData,
      token,
      facebookAdIds,
    } = this.state;

    let user = jwt.decode(token);

    if (this.props.user) {
      user = this.props.user;
    }

    const steps = [
      {
        stepInteraction: false,
        selector: ".gmb-post-card",
        content: (
          <div>
            <p>Welcome to the Conversion Logix proofing system!</p>
            <p>Here you can preview your posts, banners, and other media.</p>
          </div>
        ),
      },
      {
        selector: ".proof-comments",
        content: "Leave your comments or requests for revision in this area.",
      },
      {
        selector: ".approve-button",
        content: "Finally, approve your proofs and we will publish them as requested.",
      },
    ];

    return (
      <div style={{ height: "100%" }}>
        {user && user.isAgency && (
          <Alert
            // showIcon={false}
            message="You are proofing as Conversion Logix. Do not share this link!"
            banner
          />
        )}
        <div className={"content"} style={{ height: "100%", padding: "0 16px", marginBottom: 16 }}>
          {/* <Tour
            steps={steps}
            isOpen={this.state.isTourOpen}
            goToStep={0}
            // onAfterOpen={this.disableBody}
            onBeforeClose={() => {
              this.enableBody();
            }}
            onRequestClose={() => {
              this.setState({ isTourOpen: false });
            }}
            rounded={2}
            lastStepNextButton={<Button type="primary">Get Started</Button>}
          /> */}
          <Row>
            <Col>
              <PageHeader title="Facebook Ads Preview" />
              <div style={{ maxWidth: 1000, margin: "16px auto 0" }}>
                {this.state.client && (
                  <div>
                    <h1 style={{ display: "inline-block", marginRight: 8 }}>{this.state.client.company_name}</h1>
                    <span style={{ display: "inline-block" }}>{this.state.client.location}</span>
                  </div>
                )}

                <div
                  style={{
                    // textAlign: "center",
                    height: "100%",
                  }}
                >
                  {this.state.loadingInternal ? (
                    <LoadingIndicator />
                  ) : facebookAdIds && facebookAdIds.length > 0 ? (
                    // this.state.backendPosts.map(post => {
                    //   return <ProofItem post={post} />;
                    // })
                    <List
                      itemLayout="horizontal"
                      dataSource={facebookAdIds}
                      renderItem={(facebookAdId) => (
                        // <List.Item>
                        <FacebookAdPreview
                          post={{
                            body: {
                              proofItemType: "SOCIAL",
                              ad_format: facebookAdId.format,
                              facebookAdId: facebookAdId.id,
                            },
                          }}
                        />
                        // </List.Item>
                      )}
                    />
                  ) : (
                    <Empty description={"No ads found"} style={{ margin: "80px 0", height: "100%" }} />
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}
