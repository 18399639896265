import React, { useState } from "react";
import moment from "moment";
// import logo from "./logo.svg";
import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  // Redirect,
  // Link
} from "react-router-dom";

import {
  Layout,
  Button,
  Form,
  Icon,
  Input,
  Checkbox,
  Menu,
  Modal,
  // , Breadcrumb
} from "antd";

import GMBProofPage from "./GoogleMyBusiness/GMBProofPage";
import ProofPage from "./ProofPage";
import FacebookPreviewPage from "./FacebookPreviewPage";
import FacebookPreviewLinkGenerator from "./FacebookPreviewLinkGenerator";

const { Header, Content, Footer } = Layout;

function App(props) {
  let userToken = localStorage["user"] && JSON.parse(localStorage["user"]);

  window.moment = moment;

  if (
    userToken &&
    (!userToken.exp ||
      (userToken.exp && typeof userToken.exp !== "number") ||
      (userToken.exp && moment().isAfter(userToken.exp)))
  ) {
    userToken = null;
    delete localStorage["user"];
  }

  const [user, setUser] = useState(userToken);

  return (
    <Layout className="layout" style={{ minHeight: "100vh" }}>
      <Header>
        <div className="logo">
          <img alt="Conversion Logix" src="https://conversionlogix.com/images/logo/cl_horizontal-white.png" />
        </div>
        {user && (
          <Menu theme="dark" mode="horizontal" style={{ lineHeight: "64px" }}>
            <Menu.Item
              key="logout"
              style={{ float: "right" }}
              onClick={() => {
                setUser(null);
                delete localStorage["user"];
              }}
            >
              Log out
            </Menu.Item>
          </Menu>
        )}
      </Header>
      <Content>
        <Router>
          <Switch>
            {/* Route for previewing Facebook Ads */}
            <Route exact path="/proofs/social" render={(props) => <FacebookPreviewPage {...props} />} />
            {/* Internal route for generating links to preview Facebook Ads */}
            <PrivateRoute
              exact
              path="/proofs/social/generate"
              render={(props) => <FacebookPreviewLinkGenerator {...props} />}
              user={user}
              onLogin={(value) => {
                setUser(value);
              }}
            />
            {/* /proofs/:companyId and /proofs/:companyId/:state route are not currently used. 
            These were for an abandoned project to display multiple 
            types of proofs on one page. See README notes. */}
            <PrivateRoute
              exact
              path="/proofs/:companyId"
              render={(props) => <ProofPage {...props} user={user} />}
              user={user}
              onLogin={(value) => {
                setUser(value);
              }}
            />
            <PrivateRoute
              exact
              path="/proofs/:companyId/:state"
              render={(props) => (
                <ProofPage
                  {...props}
                  user={user}
                  onLogin={(value) => {
                    setUser(value);
                  }}
                />
              )}
              user={user}
              onLogin={(value) => {
                setUser(value);
              }}
            />

            {/* Client renewals disabled, see README notes */}
            {/* <Route
                exact
                path="/gmb/:accountId/:locationId/renew"
                render={props => <RenewGMBPosts {...props} user={user} />}
              /> */}

            {/* /gmb/:accountId/:locationId is the primary route for GMB proofing.
            :accountId/:locationId are both from the GMB API */}
            <PrivateRoute
              exact
              path="/gmb/:accountId/:locationId"
              render={(props) => (
                <GMBProofPage
                  {...props}
                  user={user}
                  onLogin={(value) => {
                    setUser(value);
                  }}
                />
              )}
              user={user}
              onLogin={(value) => {
                setUser(value);
              }}
            />
            {/* Routing for post state tabs in the GMB proofing view:  */}
            <PrivateRoute
              exact
              path="/gmb/:accountId/:locationId/:state"
              render={(props) => (
                <GMBProofPage
                  {...props}
                  user={user}
                  onLogin={(value) => {
                    setUser(value);
                  }}
                />
              )}
              user={user}
              onLogin={(value) => {
                setUser(value);
              }}
            />
            {/* <Redirect to="https://conversionlogix.com/" /> */}
          </Switch>
        </Router>
      </Content>
      <Footer style={{ textAlign: "center", zIndex: 100 }}>©{moment().year()} Conversion Logix</Footer>
    </Layout>
  );
}

const PrivateRoute = ({ component: Component, render, user, onLogin, ...rest }) => {
  return user ? (
    render ? (
      <Route {...rest} render={render} />
    ) : (
      <Route {...rest} render={(props) => <Component {...props} />} />
    )
  ) : (
    <Route {...rest} render={(props) => <LoginForm onLogin={onLogin} />} />
  );
};

const LoginForm = Form.create({ name: "login" })((props) => {
  const { getFieldDecorator } = props.form;

  return (
    <Modal centered visible title="Type your name and email address to view your proofs" footer={null} closable={false}>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          props.form.validateFields((err, values) => {
            if (!err) {
              props.onLogin(values);
              if (values.remember) {
                delete values.remember;
                values.exp = moment().add(1, "month").valueOf();
                localStorage["user"] = JSON.stringify(values);
              }
            }
          });
        }}
        // onSubmit={this.handleSubmit}
        className="login-form"
      >
        <Form.Item>
          {getFieldDecorator("name", {
            rules: [{ required: true, message: "Please enter your name" }],
          })(
            <Input size="large" prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />} placeholder="Name" />
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator("email", {
            rules: [
              {
                required: true,
                message: "Please enter your email",
              },
              {
                type: "email",
                message: "Please enter a valid email",
              },
            ],
          })(
            <Input
              size="large"
              prefix={<Icon type="mail" style={{ color: "rgba(0,0,0,.25)" }} />}
              placeholder="Email"
              type="email"
            />
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator("remember", {
            valuePropName: "checked",
            initialValue: true,
          })(<Checkbox>Remember me</Checkbox>)}
          <Button size="large" type="primary" htmlType="submit" className="login-form-button" style={{ width: "100%" }}>
            View my proofs
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
});

export default App;
